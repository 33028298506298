import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from './paymentForm';

const PUBLIC_KEY = "pk_test_51OqL3OJX29y1GMAN8twYS2MDsA9pZV4KPG1f0yoQ63apyqwNUwa5ZUB12iYpH09YMouMryqZhfYNpC26LON5y97L00xxN6S5Qa";

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer() {
    return (
        <Elements stripe={stripeTestPromise}>
            <PaymentForm />
        </Elements>
    )
}