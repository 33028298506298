import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // You only need Firebase auth now
import { onAuthStateChanged } from 'firebase/auth';
import * as fcl from '@onflow/fcl'; // Import Flow client library

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [flowAddress, setFlowAddress] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);

      if (user) {
        try {
          // Log the email to ensure it's correct
          console.log('Logged in user email:', user.email);

          // Fetch Flow address directly from the blockchain using Cadence
          const flowAddr = await fetchFlowAddress(user.email);
          
          if (flowAddr) {
            setFlowAddress(flowAddr);
            console.log('Flow Address:', flowAddr); // Log the Flow address
          } else {
            console.error('No matching Flow address found for the email.');
          }
        } catch (error) {
          console.error('Error fetching Flow address:', error);
        }
      } else {
        setFlowAddress(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Function to fetch Flow address using a Cadence script
  const fetchFlowAddress = async (email) => {
    try {
      // Execute Cadence script
      const result = await fcl.query({
        cadence: `
          import UserRegistry from 0x66b60643244a7738

          access(all) fun main(): [UserRegistry.User] {
            return UserRegistry.getUsers()
          }
        `,
      });

      // Log the entire blockchain result for debugging
      console.log('Blockchain query result:', result);

      // Find user by email and get the Flow address
      const userEntry = result.find(entry => entry.email === email);
      return userEntry ? userEntry.flowAddress : null;
    } catch (error) {
      console.error('Error fetching Flow address:', error);
      return null;
    }
  };

  return (
    <UserContext.Provider value={{ user, flowAddress, loading }}>
      {children}
    </UserContext.Provider>
  );
};
