export const buyModelX = `
// Mainnet

import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import FlowToken from 0x1654653399040a61
import MainStorefrontX1 from 0x66b60643244a7738
import TitPalace from 0xfdfe39186c5e3b90

transaction(storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {

    // Local references
    let paymentVault: @{FungibleToken.Vault}
    let nftCollection: &TitPalace.Collection
    let storefront: &MainStorefrontX1.Storefront
    let listingRef: &{MainStorefrontX1.ListingPublic}
    let mainFlowVault: auth(FungibleToken.Withdraw) &FlowToken.Vault
    let balanceBeforeTransfer: UFix64

    prepare(acct: auth(Storage, Capabilities) &Account) {

        // Step 3: Borrow a reference to the storefront where the listing is located
        self.storefront = getAccount(storefrontAddress)
            .capabilities.borrow<&MainStorefrontX1.Storefront>(MainStorefrontX1.StorefrontPublicPath)
            ?? panic("Could not borrow the storefront reference.")

        // Borrow a reference to the listing that is being purchased
        self.listingRef = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No offer with that ID in Storefront")
        let price = self.listingRef.getDetails().salePrice

        // perform check to ensure price presented to the user matches the on-chain price
        assert(expectedPrice == price, message: "Sale price not expected value")

        // Step 1: Withdraw the inputted price from the buyer's account
        self.mainFlowVault = acct.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault> (from: /storage/flowTokenVault)
            ?? panic("Cannot grab access to buyer's Flow Vault")
        self.balanceBeforeTransfer = self.mainFlowVault.balance
        self.paymentVault <- self.mainFlowVault.withdraw(amount: price)

        // Step 2: Set up the buyer's TitPalace collection if not already set up
        if acct.storage.borrow<&TitPalace.Collection>(from: TitPalace.CollectionStoragePath) == nil {
            // Create an empty TitPalace Collection if the account doesn't already have one
            let collection <- TitPalace.createEmptyCollection(nftType: Type<@TitPalace.NFT>())
            acct.storage.save(<-collection, to: TitPalace.CollectionStoragePath)

            // Publish a public capability for the TitPalace collection
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(TitPalace.CollectionStoragePath),
                at: TitPalace.CollectionPublicPath    
            )
        }

        // Borrow a reference to the buyer's collection (TitPalace Collection)
        self.nftCollection = acct.storage.borrow<&TitPalace.Collection>(
            from: TitPalace.CollectionStoragePath
        )!


    }

    execute {
        // Step 4: Purchase the listing, transferring payment and the NFTs
        let nfts <- self.listingRef.purchase(
            payment: <-self.paymentVault,
            collection: self.nftCollection
        )

        destroy nfts

        // Step 5: Return the NFTs to the buyer if necessary (destroy if unused)
        self.storefront.cleanup(listingResourceID: listingResourceID)
    }
}

`