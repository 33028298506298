export const getPortraitDetails = `

import TitPalacePortraits from 0x66b60643244a7738
import NonFungibleToken from 0x1d7e57aa55817448

access(all) fun main(accountAddress: Address): [TitPalacePortraits.titPalacePortraitsMetadata] {
    // Get the public capability for the TitPalacePortraits collection
    let collectionRef = getAccount(accountAddress)
        .capabilities.borrow<&{TitPalacePortraits.CollectionPublic}>(TitPalacePortraits.CollectionPublicPath)
        ?? panic("Could not borrow reference to the collection")

    // Get all NFT IDs in the user's collection
    let nftIDs = collectionRef.getIDs()
    
    // Prepare an array to hold the metadata of each NFT
    var nftDetails: [TitPalacePortraits.titPalacePortraitsMetadata] = []

    // Loop through all NFT IDs and fetch the metadata
    for id in nftIDs {
        let nftRef = collectionRef.borrowTitPalacePortraits(id: id)
            ?? panic("Failed to borrow reference to NFT ID")

        // Create a dictionary to store the traits
        var traitsCopy: {String: String} = {}

        // Copy each trait from the reference to the new dictionary
        for key in nftRef.traits.keys {
            traitsCopy[key] = nftRef.traits[key]!
        }

        // Create metadata object
        let metadata = TitPalacePortraits.titPalacePortraitsMetadata(
            _id: nftRef.id,
            _name: nftRef.name,
            _description: nftRef.description,
            _image: nftRef.image,
            _traits: traitsCopy // Use the dereferenced traits copy
        )

        // Append the metadata to the results array
        nftDetails.append(metadata)
    }

    // Return the array of metadata
    return nftDetails
}



`