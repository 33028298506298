import React, {createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    return (
        <GlobalContext.Provider value={{ selectedItem, setSelectedItem}}>
            {children}
        </GlobalContext.Provider>
    );
};