import React from 'react';
import StripeContainer from './stripeContainer';

function PaymentPage() {
    return (
        <div >
            <StripeContainer />
        </div>
    );
}

export default PaymentPage;
