import React, { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext'; // Import the AuthContext to access the current user
import { db } from '../../firebase'; // Import Firestore
import { doc, setDoc } from 'firebase/firestore'; // Firestore functions
import './payment.css';

export default function PaymentForm() {
    const { currentUser } = useAuth(); // Get the authenticated user
    const [selectedItem, setSelectedItem] = useState(null);
    const [success, setSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    // Fetch selectedItem from localStorage on component mount
    useEffect(() => {
        const storedItem = localStorage.getItem('selectedItem');
        if (storedItem) {
            setSelectedItem(JSON.parse(storedItem));
        } else {
            // Redirect or show error if no selected item is found
            window.location.href = '/';
        }
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
    
        if (!error) {
            try {
                const { id } = paymentMethod;
                const response = await axios.post('http://192.168.0.103:4000/payment', {
                    amount: selectedItem.price * 100, // Send price
                    id, // Payment ID from Stripe
                    imageName: selectedItem.name, // Send image name
                    userEmail: currentUser.email // Send user's email from Auth context
                });
    
                if (response.data.success) {
                    console.log('Successful Payment');
                    await savePurchasedNFT(selectedItem); // Save the purchase details to Firestore
                    setSuccess(true);
                }
            } catch (error) {
                console.log('Error', error);
            }
        } else {
            console.log(error.message);
        }
    };
        

    // Save the purchased NFT details to Firestore under the user's 'owned' collection
    const savePurchasedNFT = async (item) => {
        if (currentUser) {
            try {
                // Create a reference to the user's 'owned' collection, and use the image name as the document ID
                const docRef = doc(db, "users", currentUser.uid, "owned", item.name);

                // Save the level and xp for the purchased item
                await setDoc(docRef, {
                    level: 1,  // Set level to 1 upon purchase
                    xp: 0      // Set initial XP to 0
                });

                console.log('Purchase details saved successfully in Firestore');
            } catch (error) {
                console.error("Error writing document: ", error);
            }
        } else {
            console.error('No authenticated user found.');
        }
    };

    return (
        <>
            {!success ? (
                selectedItem ? (
                    <div className="payment-page">
                        <div className="payment-container">
                            <div className="left-column">
                                <h2>Tit Palace</h2>
                                <img src={selectedItem.image} alt={selectedItem.name} />
                                <h3>{selectedItem.name}</h3>
                                <p>Price: ${selectedItem.price}</p>
                            </div>
                            <div className="right-column">
                                <form onSubmit={handleSubmit}>
                                    <fieldset className="FormGroup">
                                        <label htmlFor="card-info" className="input-label">Card Information</label>
                                        <div className="FormRow">
                                            <CardElement />
                                        </div>
                                    </fieldset>
                                    <button type="submit" className="pay-button">PAY</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>Loading...</div>
                )
            ) : (
                <div>
                    <h2>Thank you! You just bought {selectedItem.name}!</h2>
                </div>
            )}
        </>
    );
}
