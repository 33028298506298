const maxLevel = 15; // Define max level
const baseXP = 5; // Starting XP for level 1
const growthFactor = 2; // Factor for incremental growth (instead of Euler)

// Calculate XP thresholds for each level using exponential growth
const levelsXP = Array.from({ length: maxLevel }, (_, level) => {
    return Math.floor(baseXP * Math.pow(growthFactor, level)); // Ensure XP is a whole number
});

// Log levelsXP to verify thresholds
console.log("Levels XP Array:", levelsXP); // Debugging log (remove if unnecessary)

// Function to get the XP required for the next level
export const getNextLevelXP = (currentLevel) => {
    return levelsXP[currentLevel] || null; // Return null if max level is reached
};

// Function to determine the current level based on total XP
export const getCurrentLevel = (totalXP) => {
    let level = 0;
    while (level < levelsXP.length && totalXP >= levelsXP[level]) {
        level++;
    }
    return Math.max(level - 1, 0); // Ensure level is not negative
};
