import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { initTit } from '../../cadence/initTit';
import { getPortraitListingDetails } from '../../cadence/scripts/getPortraitListingDetails';
import * as fcl from '@blocto/fcl';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import './homepage.css';
import { ThreeDots } from 'react-loader-spinner';
import { images } from '../../assets/images';
import { GlobalContext } from "../../components/globalContext/globalState";
import { getModelDetailsX2 } from "../../cadence/scripts/getModelDetailsX2";
import courtiersData from '../../cadence/data/courtiers.json'; 
import pagesData from '../../cadence/data/pages.json';
import { buyModelX } from '../../cadence/transactions/buyModelX';

import banner from '../../assets/photos/backgrounds/europeNew.png';
import icon1 from '../../assets/photos/tile1new.png';
import icon2 from '../../assets/photos/tile2new.png';
import icon3 from '../../assets/photos/tile3new.png';
import tile1 from '../../assets/photos/tiles/tile1.png';
import tile2 from '../../assets/photos/tiles/tile2.png';
import tileQuote1 from '../../assets/photos/tiles/tileQuote3.png';
import tileBig1 from '../../assets/photos/tiles/tileBig2.png';
import tileRight3 from '../../assets/photos/tiles/tileRight1.png';
import tile3 from '../../assets/photos/tiles/tile3.png';
import tile4 from '../../assets/photos/tiles/tile4.png';
import tile5 from '../../assets/photos/tiles/tile5.png';
import tile6 from '../../assets/photos/tiles/tile6.png';
import tile7 from '../../assets/photos/tiles/tile7.png';
import tile8 from '../../assets/photos/tiles/tile8.png';

import book1 from '../../assets/photos/tiles/book1.png';
import book2 from '../../assets/photos/tiles/book2.png';
import book3 from '../../assets/photos/tiles/book3.png';
import book4 from '../../assets/photos/tiles/book4.png';

function HomePage() {
    const { setSelectedItem } = useContext(GlobalContext);
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const [selectedType, setSelectedType] = useState('pages');
    const [leftTileIndex, setLeftTileIndex] = useState(0);
    const [rightTileIndex, setRightTileIndex] = useState(1);
    const [listings, setListings] = useState([]);
    const [detailResults, setDetailResults] = useState([]);
    const [selectedTraits, setSelectedTraits] = useState({});
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingCardID, setProcessingCardID] = useState(null);




    const imageCache = useMemo(() => new Map(), []);
    const storyRowRef = useRef(null);
    const imageRowRef = useRef(null);

    const tiles = [tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8];

    const scrollToStoryRow = () => storyRowRef.current.scrollIntoView({ behavior: 'smooth' });
    const scrollToImageRow = () => imageRowRef.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRightTileIndex(prevIndex => (prevIndex + 2) % tiles.length);
            setTimeout(() => setLeftTileIndex(prevIndex => (prevIndex + 2) % tiles.length), 5000);
        }, 10000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []);

    const removePurchasedListing = (listingID) => {
        setListings((prevListings) => prevListings.filter((listing) => listing.listingID !== listingID));
    };

    // Function to refresh the listings after purchase
    const refreshListings = async () => {
        await fetchAndCacheNFTData();  // Fetch fresh listings from the backend
    };

    const groupListingsByName = (listings) => {
        const grouped = [];
        const seenNames = new Map();
    
        listings.forEach((listing) => {
            if (!seenNames.has(listing.name)) {
                // If name not seen before, add it to the map with all relevant listing data
                seenNames.set(listing.name, {
                    ...listing,
                    allListingIDs: [listing.listingID],  // Store all listing IDs associated with this name
                });
            } else {
                // If the name is already seen, add the listingID to the existing entry
                const existingEntry = seenNames.get(listing.name);
                existingEntry.allListingIDs.push(listing.listingID);
            }
        });
    
        // Convert the map back to an array for rendering
        seenNames.forEach((value) => grouped.push(value));
        return grouped;
    };
    
    const fetchAndCacheNFTData = async () => {
        console.log("fetchAndCacheNFTData called");
        try {
            const portraitsResult = await fcl.query({
                cadence: getPortraitListingDetails,
                args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)], // Replace this with your actual address
            });
    
            console.log("Data received from fcl.query:", portraitsResult);
    
            let updatedListings = [...listings]; // Copy current listings state to add to it
    
            // Loop through the returned NFT details
            for (const [index, listing] of portraitsResult.entries()) {
                console.log(`Processing listing ${index + 1} of ${portraitsResult.length}`, listing);
    
                const { listingID, details: { salePrice, nftIDs } } = listing;
                const nftID = nftIDs[0]; // Handle the first NFT ID for simplicity
    
                // Fetch NFT details (from Flow or cached details)
                const nftDetails = detailResults.length > 0
                    ? detailResults.find(detail => detail.nftId === nftID.toString())
                    : await fetchPortraitDetails(nftID);
    
                if (!nftDetails || !nftDetails.name) {
                    console.log(`No details found for NFT ID: ${nftID}, skipping...`);
                    continue;
                }
    
                const { name, description, thumbnail, traits } = nftDetails;
    
                // Process traits (enrich if necessary)
                const enrichedTraits = enrichTraits(name, traits);
    
                // Check if the NFT already exists in the listings array
                const existingListingIndex = updatedListings.findIndex(item => item.name === name);
    
                if (existingListingIndex !== -1) {
                    // If the listing exists, update its listingIDs and increment the quantity
                    updatedListings[existingListingIndex].nftIDs.push(nftID);
                    updatedListings[existingListingIndex].allListingIDs.push(listingID); // Add listingID for purchase
                    updatedListings[existingListingIndex].quantity += 1;
                } else {
                    // If it's a new listing, add it to the listings array
                    updatedListings.push({
                        listingID,
                        name,
                        description,
                        salePrice,
                        thumbnail,
                        traits: enrichedTraits,
                        nftIDs: [nftID],
                        allListingIDs: [listingID], // Store listingID for this NFT
                        quantity: 1,
                    });
                }
    
                // Update the UI incrementally with the new listing
                setListings([...updatedListings]);
    
                // Cache the updated listings in localStorage incrementally
                localStorage.setItem('cachedNFTData', JSON.stringify(updatedListings));
            }
    
        } catch (error) {
            console.error("Error fetching NFT data:", error);
        }
    };
    
    
    const enrichTraits = (name, traits) => {
        console.log(`\nProcessing traits for: "${name}"`);
    
        // Check if the name indicates a courtier
        const isCourtier = ["Queen", "Baroness", "Lady", "Dame", "Countess", "Marchioness"].some(title => name.startsWith(title));
    
        let dataSource;
    
        if (isCourtier) {
            console.log(`Looking for a match in Courtiers JSON...`);
            dataSource = courtiersData.Courtiers.find(courtier => courtier.name === name);
    
            if (dataSource) {
                console.log(`Match found in Courtiers JSON for: "${name}"`);
                console.log("Full traits from JSON data:", JSON.stringify(dataSource.traits, null, 2));
    
                // Directly return the entire traits array from the JSON file
                return dataSource.traits;
            } else {
                console.log(`No match found in Courtiers JSON for: "${name}"`);
            }
        } else {
            console.log(`Looking for a match in Pages JSON...`);
            dataSource = pagesData.Pages.find(page => page.alt.split(' - ')[0].trim() === name);
    
            if (dataSource) {
                console.log(`Match found in Pages JSON for: "${name}"`);
                return enrichFromDataSource(dataSource, traits);
            } else {
                console.log(`No match found in Pages JSON for: "${name}"`);
            }
        }
    
        console.log(`No matching data source found for "${name}". Returning original traits.\n`);
        return traits;
    };
    
    
    const enrichFromDataSource = (dataSource, traits) => {
        console.log("DataSource for enrichment:", dataSource);
        console.log("Traits to enrich:", traits);
    
        // Extract traits available in JSON for comparison
        const sourceTraits = dataSource.traits || [];
        console.log("Available traits in data source:", sourceTraits);
    
        return Object.keys(traits).map(traitKey => {
            const traitNameOrValue = traits[traitKey];
            console.log(`\nProcessing trait: ${traitKey} with value "${traitNameOrValue}"`);
    
            // Find matching trait in JSON data
            const matchingTrait = sourceTraits.find(
                t => t.name === traitNameOrValue || t.category === traitKey || t.name === traitKey
            );
    
            if (matchingTrait) {
                console.log(`Match found for trait: ${traitKey} with value "${traitNameOrValue}" in JSON source`);
                console.log("Enriched trait data:", matchingTrait);
    
                // Return enriched trait with full details
                return {
                    category: matchingTrait.category || traitKey,
                    name: matchingTrait.name || traitKey,
                    value: matchingTrait.value || "Value not provided",
                    description: matchingTrait.description || "Description not provided",
                    effect: matchingTrait.effect || "Effect not provided",
                    modifier: matchingTrait.modifier || "Modifier not provided"
                };
            } else {
                console.warn(`Trait "${traitKey}" with value "${traitNameOrValue}" not found in data source.`);
                return {
                    category: traitKey,
                    name: traitNameOrValue || "Name not provided",
                    value: "Value not provided",
                    description: "Description not provided",
                    effect: "Effect not provided",
                    modifier: "Modifier not provided"
                };
            }
        });
    };
    
    
    
    
    const fetchPortraitDetails = async (nftID) => {
        console.log(`Fetching portrait details for NFT ID: ${nftID}`);
        
        try {
            const detailsResult = await fcl.query({
                cadence: getModelDetailsX2,  // This is your new script
                args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)], // Make sure this matches the user address context
            });
    
            console.log("Details received from getModelDetails query:", detailsResult);
    
            // Find the matching NFT based on nftID
            return detailsResult.find(detail => detail.nftId === nftID.toString());
        } catch (error) {
            console.error("Error fetching portrait details for NFT ID:", nftID, error);
            return null;
        }
    };
    
    

    useEffect(() => {
        const cachedNFTData = localStorage.getItem('cachedNFTData');
        if (cachedNFTData && JSON.parse(cachedNFTData).length > 0) {
            console.log("Using cached NFT data:", JSON.parse(cachedNFTData));
            setListings(JSON.parse(cachedNFTData));
        } else {
            // Fetch and display listings if cache is empty
            fetchAndCacheNFTData();
        }
    }, []);
    
    const renderImage = (thumbnail, altName) => {
        // Return cached image if available
        if (imageCache.has(altName)) {
            return imageCache.get(altName);
        }
    
        let imgPath = "";
    
        // Determine the image path based on the thumbnail or courtier data
        if (thumbnail === "N/A" || !thumbnail.includes("img")) {
            const courtier = images.courtierImages.find(c => c.alt === altName);
            imgPath = courtier ? courtier.src : "";
        } else {
            let imgNum = parseInt(thumbnail.replace("img", ""));
            if (imgNum > 38) {
                imgNum -= 1;  // Adjust for potential indexing issues
            }
            imgPath = images.popupImages[imgNum - 1]?.src || "";
        }
    
        // Cache the image path
        imageCache.set(altName, imgPath);
    
        return imgPath;
    };
    

    // Step 1: Create renderPortraitTile function to render each listing
    const renderPortraitTile = (listing) => (
        <PortraitTile
            key={listing.listingID}
            listing={listing}
            selectedQuantities={selectedQuantities}
            setSelectedQuantities={setSelectedQuantities} // Pass down the setter function
        />
    );
    const filteredListings = useMemo(() => {
        const validListings = listings.filter((listing) => listing.allListingIDs && listing.allListingIDs.length > 0);
    
        const filtered = validListings.filter(listing =>
            selectedType === 'pages'
                ? listing.thumbnail && listing.thumbnail.includes("img")
                : listing.thumbnail && !listing.thumbnail.includes("img")
        );
    
        console.log("Filtered Listings for type:", selectedType, filtered);
    
        // Group the filtered listings by name
        return groupListingsByName(filtered);
    }, [listings, selectedType]);
    
    const getDetailedTraits = useMemo(() => {
        return (listing) => {
            // If traits are already selected for this listing, return them
            if (selectedTraits[listing.listingID]) {
                return selectedTraits[listing.listingID];
            }
    
            let randomTraits = [];
            if (selectedType === 'pages') {
                const pageData = pagesData.Pages.find(page => 
                    page.alt.split(' - ')[0].trim().toLowerCase() === listing.name.trim().toLowerCase()
                );
        
                if (pageData && pageData.traits) {
                    randomTraits = pageData.traits
                        .sort(() => 0.5 - Math.random())
                        .slice(0, 2);
                } else {
                    console.warn(`No page data found for ${listing.name}`);
                }
    
            } else if (selectedType === 'courtiers') {
                const courtierData = courtiersData.Courtiers.find(courtier => 
                    courtier.Name && courtier.Name.trim().toLowerCase() === listing.name.trim().toLowerCase()
                );
        
                if (courtierData && courtierData.traits) {
                    randomTraits = courtierData.traits
                        .sort(() => 0.5 - Math.random())
                        .slice(0, 2);
                } else {
                    console.warn(`No courtier data found for ${listing.name}`);
                }
            }
    
            // Save the selected traits in state so they don't re-randomize
            setSelectedTraits(prevState => ({
                ...prevState,
                [listing.listingID]: randomTraits
            }));
    
            return randomTraits;
        };
    }, [selectedTraits, selectedType]);
    
    
    
    const PortraitTile = React.memo(({ listing, selectedQuantities, setSelectedQuantities }) => {
        const quantity = selectedQuantities[listing.listingID] || 1;
        const totalPrice = (quantity * parseFloat(listing.salePrice)).toFixed(2);
    
        const displayedTraits = useMemo(() => getDetailedTraits(listing), [listing, selectedType]);
    
        const handleQuantityChange = (e) => {
            const newQuantity = Number(e.target.value);
            setSelectedQuantities(prevState => ({
                ...prevState,
                [listing.listingID]: newQuantity
            }));
        };

        const calculateHP = (traits, listingName) => {
            console.log(`Calculating HP for listing: "${listingName}"`);
            console.log(`Traits provided:`, traits);
        
            // Use traits if valid
            if (Array.isArray(traits) && traits.length > 0) {
                console.log(`Using provided traits for "${listingName}":`, traits);
                return traits.reduce((sum, trait) => {
                    const value = trait && trait.value ? Number(trait.value) : 0;
                    return sum + (isNaN(value) ? 0 : value); // Ensure valid number
                }, 0);
            }
        
            // If no valid traits, fetch from `courtiers.json`
            console.warn(`Traits for "${listingName}" are not valid. Fetching from courtiers.json.`);
            const courtier = courtiersData.Courtiers.find(
                (c) => c.Name.trim().toLowerCase() === listingName.trim().toLowerCase()
            );
        
            if (courtier && courtier.traits && Array.isArray(courtier.traits)) {
                console.log(`Traits for "${listingName}" from courtiers.json:`, courtier.traits);
                return courtier.traits.reduce((sum, trait) => {
                    const value = trait && trait.value ? Number(trait.value) : 0;
                    return sum + (isNaN(value) ? 0 : value); // Ensure valid number
                }, 0);
            }
        
            // If all else fails, return default HP
            console.warn(`No traits found for "${listingName}" in courtiers.json.`);
            return 0;
        };
        

        const hpValue = useMemo(() => {
            const traits = listing.traits || []; // Default to empty array if traits are undefined
            const name = listing.name || ''; // Default to empty string if name is undefined
            return calculateHP(traits, name);
        }, [listing.traits, listing.name]);
        
        console.log(`Final HP for "${listing.name}": ${hpValue}`);
        

        
        const handleBuy = async (listing) => {
            try {
                setProcessingCardID(listing.listingID);
                setIsProcessing(true);
        
                // Re-fetch the latest listings from the blockchain
                const refreshedListings = await fcl.query({
                    cadence: getPortraitListingDetails, // Your Cadence script to fetch listings
                    args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)], // Replace with your storefront address
                });
        
                // Cross-reference listings with relevant NFT IDs for the card
                const relevantListings = refreshedListings.filter((refreshed) =>
                    listing.nftIDs.includes(refreshed.details.nftIDs[0]) // Match NFT IDs
                );
        
                console.log(`Relevant Listing IDs for "${listing.name}":`);
                relevantListings.forEach((refreshed) => {
                    console.log(`Listing ID: ${refreshed.listingID}, NFT ID: ${refreshed.details.nftIDs[0]}, Purchased: ${refreshed.details.purchased}`);
                });
        
                // Optionally, find the first available listing for purchase
                const availableListing = relevantListings.find((refreshed) => !refreshed.details.purchased);
        
                if (!availableListing) {
                    console.log(`No available listings found for "${listing.name}".`);
                    alert(`"${listing.name} is currently sold out.`);
                    setProcessingCardID(null);
                    setIsProcessing(false);
                    return;
                }
        
                console.log(`Using ListingID: ${availableListing.listingID} for purchase.`);
        
                // Proceed with the purchase transaction
                const txid = await fcl.mutate({
                    cadence: buyModelX,
                    args: (arg, t) => [
                        arg('0xfdfe39186c5e3b90', t.Address),  // Replace with correct storefront address
                        arg(availableListing.listingID, t.UInt64), // Use valid listingID here
                        arg(availableListing.details.salePrice, t.UFix64),
                    ],
                    proposer: fcl.currentUser,
                    payer: fcl.currentUser,
                    authorizations: [fcl.currentUser],
                    limit: 999,
                });
        
                console.log(`Transaction ID: ${txid}`);
                await fcl.tx(txid).onceSealed();
        
                // After purchase, refresh the listings again to keep the UI in sync
                refreshListings();
        
                setProcessingCardID(null);
                setIsProcessing(false);
            } catch (error) {
                console.error("Error during purchase:", error);
                setProcessingCardID(null);
                setIsProcessing(false);
            }
        };
        
        // Add this function to render each trait
        const renderTrait = (trait, index) => (
            <div key={index} className="trait">
                <div className="trait-row-1">
                    <div className="trait-category-name">
                        {trait.category}: {trait.name}
                    </div>
                    <div className="trait-value">
                        {trait.value}
                    </div>
                </div>
                <div className="trait-row-2">
                    <div className="trait-description">
                        <small>{trait.description}</small>
                    </div>
                </div>
            </div>
        );
    
        return (
            <div className="portrait-tile">
                <div className="name-hp-container">
                    <h3>{listing.name}</h3>
                    <div className="hp-container">
                        <span className="hp-value">{hpValue}</span>
                        <span className="hp-label">HP</span>
                    </div>
                </div>
                <div className="image-banner-2">
                    <img src={renderImage(listing.thumbnail, listing.name)} alt={listing.name} />
                    <div className="description-banner">{listing.description}</div>
                </div>
                <div className="portrait-details">
                    {displayedTraits.length > 0
                        ? displayedTraits.map((trait, index) => renderTrait(trait, index))
                        : <span>No traits available</span>}
                </div>
                <div className="purchase-section">
                    {processingCardID === listing.listingID ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <ThreeDots
                                height="40"
                                width="80"
                                radius="9"
                                color="rgba(75, 0, 130, 0.95)"
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="price-column">
                                {`$${parseFloat(totalPrice).toFixed(2)} FLOW`}
                            </div>
                            <div className="button-column">
                                <button className="buy-button" onClick={() => handleBuy(listing)}>BUY</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    });
    
    
    return (
        <div className="homepage">
            <div className="banner-image">
                <img src={tiles[leftTileIndex]} alt="Left Tile" className="tile" />
                <img src={tileQuote1} alt="Tile Quote 1" className="tile" />
                <img src={tiles[rightTileIndex]} alt="Right Tile" className="tile" />
                <div className="tile-big-container">
                    <img src={tileBig1} alt="Tile Big 1" className="tile tile-big" />
                    <div className="overlay-content">
                        <h2>SEX & SECRETS</h2>
                        <p>Unveil the hidden allure of the Tit Palace</p>
                        <div className="button-row">
                            <button className="overlay-button" onClick={scrollToStoryRow}>Enamor</button>
                            <button className="overlay-button" onClick={scrollToImageRow}>Revel</button>
                        </div>
                    </div>
                </div>
                <img src={tileRight3} alt="Tile 3" className="tile tile-small" />
            </div>
            <div className="type-selector">
                <button onClick={() => setSelectedType('pages')} className="type-button">Pages</button>
                <button onClick={() => setSelectedType('courtiers')} className="type-button">Courtiers</button>
            </div>
            <div className="image-row">
                {filteredListings.map((listing) => renderPortraitTile(listing))}
            </div>
            <div className="story-row" ref={storyRowRef}>
                <div className="book-tile">
                    <img src={book1} alt="book cover 1" />
                    <h5>Forbidden Whispers</h5>
                    <p>Housekeeper Eliza and Countess Eleanor share a charged moment of admiration and unspoken desires within the luxurious halls of Tit Palace.</p>
                    <a>Coming Soon</a>
                </div>
                <div className="book-tile">
                    <img src={book2} alt="book cover 2" />
                    <h5>Afternoon Encounter</h5>
                    <p>Chef Mabel admires Dame Victoria's grace, leading to an unexpected, ravenous scamper in Victoria's private quarters.</p>
                    <Link to="/afternoon-encounter" onClick={() => window.scrollTo(0, 0)}>Read Now</Link>
                </div>
                <div className="book-tile">
                    <img src={book3} alt="book cover 3" />
                    <h5>Palace Temptations</h5>
                    <p>Isabelle battles duty and forbidden desire for Lady Eleanor within Tit Palace's opulent walls.</p>
                    <a>Coming Soon</a>
                </div>
                <div className="book-tile">
                    <img src={book4} alt="book cover 4" />
                    <h5>Emerald Seduction</h5>
                    <p>Countess Charlotte Greenwood's garden encounter with the enigmatic Lord Sebastian Thornwood sparks intrigue and desire.</p>
                    <a>Coming Soon</a>
                </div>
            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
        </div>
    );
}

export default HomePage;
